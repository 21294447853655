<template>
  <div>
    <select-shop :loadingButton="loadingButton" v-on:getData="getOptionGroup"></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CAlert id="productOptionGroupDeleteAlert" color="danger" v-if="isDeleteSuccess === true" :show="Number(3)">
          {{ $t('deleteSuccess') }}
        </CAlert>
        <CCardBody>
          <div class="table">
            <div class="row">
              <div class="col-md-10 col-sm-9 col-8">
                <h2 class="font-weight-normal text-dark">
                  {{ $t('txtOptionCategory') }}
                </h2>

              </div>
              <div class="col-md-2 col-sm-3 col-4 text-right text-success" v-if="isEditData">
                <CButton id="productOptionGroupAddButton" block color="success" style="display: flex; align-items: center; justify-content: center;" @click="addOptionGroupButton()">
                  <i class="fi fi-rr-plus" style="margin-right: 8px;"></i>
                  {{ $t('addOptionGroup') }}
                </CButton>
              </div>
            </div>
          </div>
          <CRow class="mt-3">
            <CCol sm="12" lg="12">
              <div v-if="loadingButton == true">
                <CDataTable id="productOptionGroupDataTable" :fields="fields" :items="items" :loading="loading" border hover clickableRows
                  style="white-space: nowrap; width: 100%;" @row-clicked="toggleDetails">
                  <template #name="{ item }">
                  <td class="py-2 text-dark font-weight-normal ">
                    {{ item.name }}  <img src="/img/delivery/grab.png" v-if="item.source === 'GRAB'" class="rounded-circle"
                    style="border-radius: 3px; width: 20px;" />
                  </td>
                </template>
                  <template #action="{ item, index }" >
                    <td :id="'productOptionGroupName' + index" class="text-center" v-if="isEditData">
                      <CRow>
                        <CCol>
                          <router-link style="text-decoration:none;" :to="{
                                name: 'EditOptionGroup',
                                params: { objectId: item.objectId },

                              }">
                            <img :id="'productOptionGroupEditLink'+ index" src="/img/file-edit.png" style="width: 14px; height: 14px; cursor: pointer;"/>  
                          </router-link>
                        </CCol>
                        <CCol>
                          <img :id="'productOptionGroupDeleteIcon'+ index" src="/img/trash.png" style="width: 14px; height: 14px; cursor: pointer; "
                          @click="confirmdelete(item)">
                        </CCol>
                      </CRow>
                    </td>
                  </template>
                  <!-- <div></div>
                    <template #edit="{ item }">
                      <router-link style="text-decoration:none;" :to="{
                        name: 'EditOptionGroup',
                        params: { objectId: item.objectId },
                      }">
                        <i width="30" style="color: #3abc98" class="far fa-edit fa-lg cursor"></i>
                      </router-link>
                    </template>
                    <template #delete="{ item }">
                      <CCol style="margin-left: 50px ;">
                        <i class="fa fa-trash-o cursor" style="color: red" @click="confirmdelete(item.objectId)"></i>
                      </CCol>
                    </template> -->

                  <template #details="{ item, index }" @click="toggleDetails(item)">
                    <CCollapse :id="'productOptionGroupDetailsCollapse'+ index" :show="details.includes(item)">
                      <CCardBody border-color="light">
                        <table style="width:100%" class="table table-borderless">
                          <th class="text-left pl-3 text-dark">{{ $t('optional') }}</th>
                          <tr v-for="(optionItem, index) in item.optionItem" :key="index">
                            <td class="text-left pl-5">
                              <h6 class="text-dark "> - {{ optionItem }}</h6>
                            </td>
                          </tr>
                        </table>
                      </CCardBody>
                    </CCollapse>
                  </template>
                  <template #no-items-view>
                    <div class="text-center my-5">
                      <h4 style="color: #ced2d8;">
                        {{ $t('noItem') }}
                      </h4>
                    </div>
                  </template>
                </CDataTable>
              </div>
              <CListGroupItem v-if="loadingButton == false">
                <div class="text-center" width="100%">
                  <button id="productOptionGroupLoadingFallbackButton" class="btn" type="button" disabled style="color: darkcyan">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">
                    </span>
                    กำลังโหลด . . .
                  </button>
                </div>
              </CListGroupItem>
            </CCol>
          </CRow>

          <pagination :meta_data="meta_data" v-on:next="getOptionGroup">
          </pagination>
        </CCardBody>
      </CCard>
      <CRow>
        <CModal color="danger" :title="$t('confirmDeletGroup')" :show.sync="deleteModal" centered>
          <h4 class="mt-4 mb-4 text-center">{{ $t('dialigDeleteGroup') }}</h4>
          <template #footer>
            <CRow class="justify-content-around col-md-12">
              <CCol col="4">
                <CButton id="productOptionGroupConfirmDeleteButton" v-if="loadingDeleteButton === false" @click="deleteOptionGroup(itemDelete)" color="danger"
                  block>
                  {{ $t('delete') }}
                </CButton>
                <CButton id="productOptionGroupConfirmDeleteButtonDisabled" v-else-if="loadingDeleteButton === true" color="danger" block disabled>
                  <CSpinner color="white" size="sm" />
                </CButton>
              </CCol>
              <CCol col="4">
                <CButton id="productOptionGroupCancelDeleteButton" v-if="loadingDeleteButton === false" @click="deleteModal = false" color="light" block>
                  {{ $t('cancel') }}
                </CButton>
                <CButton id="productOptionGroupCancelDeleteButtonDisabled" v-else-if="loadingDeleteButton === true" color="light" disabled block>
                  {{ $t('cancel') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/util/util'
import pos from '@/services/local'
import Pagination from '@/containers/Pagination'
import permis from '@/util/permission'
// import local from '@/services/local'

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      data: [],
      loadingButton: true,
      keyword: '',
      categoryObjectId: '',
      category: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: 25,
        itemCount: null,
      },
      confirmModal: false,
      loadingDeleteButton: false,
      loading: false,
      message: '',
      footer: false,
      isSelfOrderEnabled: '',
      details: [],
      deleteModal: false,
      itemDelete: {},
      isDeleteSuccess: false,
      loadingButton: true

    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'isOwner']),
    isPermission() {
      return permis.findPermissionRead('product', this.$route.path)
    },
    isEditData() {
      return permis.findPermissionEdit('product', this.$route.path)
    },
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    selfOrderEnabled: {
      get() {
        const shop = this.shop || {}
        const delivery = shop.delivery || {}
        if (delivery.isSelfOrderEnabled != undefined) {
          this.isSelfOrderEnabled = delivery.isSelfOrderEnabled
        } else {
          this.isSelfOrderEnabled = false
        }
      },
      set(value) {
        this.isSelfOrderEnabled = value
      },
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    shop() {
      return this.shops.find((i) => i.objectId === this.shopObjectId)
    },
    noImg() {
      return process.env.VUE_APP_NOIMAGE
    },
    fields() {
      if (this.isEditData) {
        return [
          { key: 'index', label: '#', _style: 'width:2%', _classes: 'text-dark font-weight-normal' },
          { key: 'name', label: this.$i18n.t('txtOptionGroup'), _classes: 'font-weight-normal text-dark' },
          { key: 'action', label: '', _style: 'width:10%', _classes: 'text-dark font-weight-normal' },
        ]
      } else {
        return [
          { key: 'index', label: '#', _style: 'width:2%', _classes: 'text-dark font-weight-normal' },
          { key: 'name', label: this.$i18n.t('txtOptionGroup'), _classes: 'font-weight-normal text-dark' },
        ]
      }
    },
    items() {
      let data = this.data
      let objects = []
      const currentPage = (this.meta_data.current_page || 1) - 1
      const limit = this.meta_data.limit || 25

      for (let i = 0; i < data.length; i++) {
        const object = data[i]
        const index = (currentPage * limit) + (i + 1)
        const source = data[i].source ? data[i].source : ''
        let item = {
          'index': index,
          'name': object.name,
          'objectId': object.objectId,
          'action': '',
          'optionItem': object.items.map(item => item.optionItem.name),
          id: object.id,
          items: object.items,
          source: source
        }
        objects.push(item)
      }
      return objects
    },
  },
  created() {
    this.getOptionGroup()
  },
  methods: {
    ...util,
    confirmdelete(item) {
      this.deleteModal = true
      this.itemDelete = item
    },
    addOptionGroupButton() {
      this.$router.push('optiongroup/addOptionGroup');
    },
    cancel() {
      this.isSelfOrderEnabled = !this.isSelfOrderEnabled
      this.confirmModal = false
    },
    statusPopup() {
      if (this.isSelfOrderEnabled) {
        this.message = this.$i18n.t('confirmSelfOrderDisable')
      } else {
        this.message = this.$i18n.t('confirmSelfOrderEnable')
      }
      this.confirmModal = true
    },
    getOptionGroup() {
      this.loadingButton = false
      const uid = this.uid
      const shopObjectId = this.shopObjectId
      let params = { shopObjectId: shopObjectId }
      pos
        .get('/api/V1.0/' + uid + '/option/group/get', { params })
        .then((res) => {
          this.data = res.data.data
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          if (res.data.data !== undefined && Array.isArray(res.data.data)) {
            // กรองออกเฉพาะข้อมูลที่ไม่มี deleted_at
            this.data = res.data.data.filter(item => item.deleted_at === undefined);
          } else {
            this.data = [];
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.isDeleteSuccess = false
          this.loadingButton = true
        })
    },
    deleteOptionGroup(itemDelete) {
      this.isDeleteSuccess = false
      this.loadingeDeleteButton = true;
      const uid = `${localStorage.getItem('shopsUid')}`;
      const shopObjectId = this.shopObjectId;
      let data = []
      data = {
        id: itemDelete.id,
        objectId: itemDelete.objectId,
        name: this.groupName,
        maximum: this.maximum,
        required: this.required,
        uid: uid,
        deleted_at: new Date(),
        shop: {
          objectId: this.shop.objectId,
          id: this.shop.id,
          branchName: this.shop.branchName,
        },
        items: itemDelete.items
      }
      const headers = { shopObjectId: shopObjectId };

      pos
        .post(`/api/V1.0/${uid}/option/group/update`, [data], { headers })
        .then(
          setTimeout(() => {
            this.loadingDeleteButton = false;
            this.confirmModal = false;
            this.isDeleteSuccess = true;
            this.deleteModal = false;
            this.getOptionGroup()
          }, 1000)
        )
        .catch((error) => {
          console.log(error);
        });
    },

    // deleteOptionGroup(item) {
    //   this.loadingeButton = true
    //   const uid = `${localStorage.getItem('shopsUid')}`
    //   const shopObjectId = this.shopObjectId
    //   let data = []
    //   if (item !== '') {
    //     data = {
    //       objectId: ''
    //         };


    //   } else {
    //     data = {
    //       objectId: this.groupObjectId,
    //       name: this.groupName,
    //       maximum: this.maximum,
    //       required: this.required,
    //       uid: uid,
    //       shop: {
    //         objectId: this.shop.objectId,
    //         id: this.shop.id,
    //         branchName: this.shop.branchName,
    //       },
    //       items: this.addlist.map(item => {
    //         return {
    //           optionItem: {
    //             id: item.optionItem.optionItemId,
    //             objectId: item.optionItem.optionItemObjectId,
    //             name: item.optionItem.optionItemName
    //           },
    //         };
    //       })
    //     }
    //   }
    //   const headers = { shopObjectId: shopObjectId }

    //   local({
    //     method: 'post',
    //     url: '/api/V1.0/' + uid + '/option/group/update',
    //     data: data,
    //     headers: headers,
    //   })
    //     .then(
    //       setTimeout(() => {
    //         this.loadingButton = false
    //         this.confirmModal = false,
    //           console.log("save")
    //         this.$router.push('/product/optiongroup')

    //       }, 1000)
    //     )
    //     .catch((error) => {
    //       console.log(error)
    //       this.loadingButton = false
    //     })
    // },
    toggleDetails(item) {
      const position = this.details.findIndex(detailItem => detailItem.objectId === item.objectId);

      if (position !== -1) {
        this.details.splice(position, 1);
      } else {
        this.details.push(item);
      }

    },
    floatValue(value) {
      return parseFloat(value)
    }
  },
  watch: {
    shopObjectId() {
      this.getOptionGroup()
    },
  },
}
</script>
<style>
.square-box {
  position: relative;
  width: 20%;
  overflow: hidden;
  border-radius: 3px;
}

.square-box:before {
  content: '';
  display: block;
  padding-top: 100%;
}
</style>
